<template>
    <section class="divulgation">
        <h4>Não se preocupe, nós te ajudamos a <span>extrair o melhor da plataforma</span></h4>
        <div class="list">
            <component v-if="isMobile" :is="divulgationListMobile" :list="listAccordions" />
            <component v-else :is="divulgationListDesktop" :list="listAccordions" />
        </div>
    </section>
</template>
<script setup>
import { useQuasar } from 'quasar';
import { defineAsyncComponent, computed } from 'vue'

const divulgationListMobile = defineAsyncComponent(() => import('../components/divulgationListMobile.vue'))
const divulgationListDesktop = defineAsyncComponent(() => import('../components/divulgationListDesktop.vue'))

const $q = useQuasar();

const isMobile = computed(() => {
    return $q.screen.xs
})

const listAccordions = [
    {
        title: 'Consultora',
        summary: `Se você já teve experiência revendendo produtos de outras marcas, seja por catálogo de produtos, WhatsApp ou até nas redes sociais, o Flipnet é a solução perfeita para você!
        Aqui, você usa sua rede de contatos estabelecida e expertise em vendas para divulgar mais marcas sem precisar investir um valor financeiro e ainda poder acompanhar seus resultados em um só lugar.
        `,
        opened: true,
        img: 'https://flip.net.br/wp-content/uploads/2024/06/consultora.webp'
    },
    {
        title: 'Empreendedor',
        summary: `Se você busca por oportunidades de negócio e crescimento é possível usar a plataforma para criar suas próprias campanhas de marketing, oferecer produtos de terceiros e ainda <strong>monetizar com isso</strong>.`,
        img: 'https://flip.net.br/wp-content/uploads/2024/06/empreendedor.webp'
    },
    {
        title: 'Afiliado',
        summary: `Se você trabalha fazendo campanhas pagas ou até tem um site onde divulga produtos e serviços de outras marcas em troca de comissão, aqui você vai encontrar excelentes benefícios nas parcerias além de um <strong>amplo leque de marcas</strong>.`,
        img: 'https://flip.net.br/wp-content/uploads/2024/06/afiliado.webp'
    },
    {
        title: 'Criador de conteúdo',
        summary: `Se você produz conteúdo original e de alta qualidade em diversas plataformas, como YouTube, Instagram, TikTok, blogs e podcasts, com o Flipnet você consegue uma maneira de monetizar suas redes. Use o poder de seu público engajado e segmentado, para fazer parceria com marcas que desejam alcançar nichos específicos.`,
        img: 'https://flip.net.br/wp-content/uploads/2024/06/criador-de-conteudo.webp'
    },
    {
        title: 'Influenciador',
        summary: `Se você tem uma grande gama de seguidores e exerce influência sobre seu público, o Flipnet é uma ótima solução para você conseguir se aproximar de grandes marcas e trazer ainda mais relevância para o seu perfil, podendo fechar até contratos exclusivos.`,
        img: 'https://flip.net.br/wp-content/uploads/2024/06/influenciador.webp'
    },
    {
        title: 'Profissionais em geral',
        summary: `Se você é um profissional liberal e oferece serviços para outros colegas e pequenas empresas, você pode usar a plataforma do Flipnet para encontrar colaboradores, gerenciar estratégias, medir resultados e realizar suas vendas`,
        img: 'https://flip.net.br/wp-content/uploads/2024/06/profissional-liberal.webp'
    },
]

</script>
<style scoped>
.divulgation {
    margin-top: 51px;
}

.divulgation h4 {
    font-weight: 700;
    text-align: center;
    max-width: 90%;
    line-height: 1.5;
    margin: 0 auto;
    font-size: max(20px, min(5vw, 32px));
    font-weight: 600;
}

.divulgation h4 span {
    border-bottom: 2px solid var(--flip-color-primary);
    color: var(--flip-color-primary);
    font-weight: 700;
}

.divulgation .list {
    margin-top: 52px;
}

@media (min-width: 599.99px) {
    .divulgation {
        margin-top: 120px;
    }
}
</style>