<template>
    <div class="courses q-mx-sm">
        <h4>Oferecemos <span>cursos</span> gratuitos</h4>
        <p>Conteúdos focados e objetivos</p>
        <div class="courses-inner" v-if="courses?.length">
            <div class="swiper swiper-courses">
                <div class="swiper-wrapper">
                    <div v-for="( course, index ) in courses" :key="index" class="swiper-slide">
                        <div class="swiper-slide-media">
                            <img :src="course.images_videos" />
                        </div>
                    </div>
                </div>
                <div class="controls">
                    <button class="slidePrev">
                        <q-icon name="chevron_left"/>
                    </button>
                    <button class="slideNext">
                        <q-icon name="chevron_right"/>
                    </button>
                </div>
            </div>
            <div class="course-description-content" :key="activeSlide">
                <h6 v-text="courses[activeSlide].name"/>
                <div class="course-description-text" 
                    v-html="cleanedText(courses[activeSlide].description)" 
                />
                <a @click="goToForm" class="button-go-to-form">
                    Quero começar agora
                </a>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, defineProps, watch } from 'vue';
import { landingPageGetCourses } from '../services';

const props = defineProps({
    swiperLoaded: Boolean,
    goToForm: {
        type: Function,
        required: true
    }
})

const courses = ref(null);
const activeSlide = ref(1); // Slide ativo


// Quando a lib Swiper é 100% carregado 'swiperLoaded' vem true... executando a criação dos slides
watch(() => props.swiperLoaded, (newValue) => {
    if (newValue) {
        getCourses()
    }
}, { immediate: true })



// METHODS ================================================================================================

// Limpa o texto removendo caracteres especiais e tags HTML
const cleanedText = (text) => {
    if ( !text ) return;

    // Remover caracteres como \" e \\ e aspas "
    text = text.replace(/\\["\\]/g, '').replace(/["]/g, '');

    // Remover todas as tags HTML
    text = text.replace(/<\/?[^>]+(>|$)/g, '');

    // Remover múltiplos espaços em branco
    text = text.replace(/\s{2,}/g, ' ').trim();

    return text;
}


// Cria o carousel de cursos
const initializeSwiper = () => {
    setTimeout(() => {
        new window.Swiper('.swiper-courses', {
            speed: 900,
            effect: "cards",
            grabCursor: true,
            spaceBetween: 20,
            longSwipesRatio: 0.2,
            initialSlide: activeSlide.value,
            on: {
                slideChange: function () {
                    activeSlide.value = this.activeIndex;
                },
            },
            navigation: {
                nextEl: '.slideNext',
                prevEl: '.slidePrev',
            },
        });
    })
}


// Busca os cursos no serviço
const getCourses = () => {
    landingPageGetCourses()
        .then(output => {
            const { data } = output;

            if (!data) throw new Error('Nenhum curso')

            courses.value = data

            initializeSwiper()
        })
        .catch(error => {
            console.error('Houve um erro ao buscar os cursos', error)
        })
}
</script>
<style scoped>
.courses {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 8px;
    padding: 1.5rem;
}

.courses > p {
    text-align: center;
    font-size: clamp(15px, 3.5vw, 18px);
}

.courses p > span {
    color: var(--flip-color-primary);
    font-weight: 600;
}

.course-description-content {
    max-width: 100%;
    position: relative;
}

.course-description-content h6 {
    font-size: clamp(16px, 4vw, 18px);
    font-weight: 500;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 95%;
    white-space: nowrap;
}

.course-description-text {
    font-size: clamp(12px, 3.8vw, 16px);
    color: #667082;
    display: -webkit-box; 
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
    overflow: hidden; 
    text-overflow: ellipsis;
}

.controls {
    display: flex;
    gap: 10px;
    margin-top: 4px;
    justify-content: center;
}

.controls button {
    outline: none;
    border: none;
    background-color: #1d1e2c;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.controls button:hover {
    opacity: 0.9;
}

.controls button :deep(.q-icon) {
    color: #fff;
}

ul h6 {
    font-weight: 600;
    font-size: clamp(13px, 3.5vw, 16px);
}

ul p {
    font-size: clamp(12px, 3.5vw, 14px);
}

.courses a {
    height: 48px;
    font-weight: 700;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    padding-left: 0px;
    max-width: 100%;
    margin-top: 25px;
    font-size: clamp(12px, 4vw, 18px);
    text-transform: initial;
    border: 1px solid var(--flip-color-primary);
    background-color: transparent;
    color: var(--flip-color-primary);
    transition: 150ms background ease;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

.courses a:hover {
    background: var(--flip-color-primary);
    color: #fff;
}

h4 {
    font-weight: 600;
    text-align: center;
    margin: 7px 0px;
    font-size: clamp(20px, 5vw, 32px);
}

h4 > span {
    position: relative;
    color: var(--flip-color-primary)
}

h4 > span::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 94%;
    height: 3px;
    background-color: var(--flip-color-primary);
    transform-origin: 0 100%;
    transform: rotate(-2deg);
}

.swiper {
    margin: 2rem 0.5rem 1.5rem 0.5rem
}

.swiper-slide {
    box-shadow: rgba(10, 9, 9, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 0.5rem;
    display: grid;
    overflow: hidden; 
}

.swiper-slide .swiper-slide-media>img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

@media (min-width: 599.99px) {
    .courses {
        padding: 3.75rem;
    }

    .courses-inner {
        display: flex;
        align-items: center;
        gap: 75px;
        margin: 55px 0px 0px 0px
    }

    .courses-inner > div:first-child {
        width: 53%;
    }

    .courses-inner > div:last-child {
        width: 47%;
        align-self: baseline;
        max-width: 400px;
    }

    .courses a {
        margin-top: 35px;
    }

    .course-description-content h6 {
        margin-bottom: 16px;
    }

    .course-description-text {
        -webkit-line-clamp: 6; 
    }
    
    .controls {
        margin-top: 15px;
    }

    .missions {
        margin-top: 90px;
    }

    .swiper {
        margin: 0px
    }

    .swiper-slide {
        display: flex;
        height: 270px;
        margin: 0px
    }

    .swiper-slide .swiper-slide-media {
        width: 100%;
    }

    .swiper-slide .credits {
        margin-top: 10px
    }

    .swiper-slide .swiper-slide-media>img {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-top-right-radius: 0rem;
    }
}

</style>