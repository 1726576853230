<template>
    <section class="plans">
        <div class="plans-content max-width">
            <div class="plans-inner">
                <h6>Deixe a tecnologia e o treinamento conosco.</h6>
                <p>
                    Você pode começar <strong>sem nenhum custo</strong>, e ainda temos um plano especial para acelerar o seu progresso.
                </p>
            </div>
            <div class="plans-cards">
                <div v-for="( plan, index ) in plans" :key="index" 
                class="plan-card">
                    <p>R${{ plan.price }}</p>
                    <a v-text="plan.first_button_text"></a>
                    <ul>
                        <li v-for="( item, indexItem ) in plan.ul" :key="indexItem">
                            <img :src="checkSvg">
                            <p>{{ item }}</p>
                        </li>
                    </ul>
                    <a @click="goToForm" class="button-go-to-form" v-text="plan.second_button_text" />
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
    import { defineProps } from 'vue';
    import checkSvg from '@/assets/icons/check.svg';

    defineProps({
        goToForm: {
            type: Function,
            required: true
        }
    })

    const plans = [
        {
            price: 0,
            first_button_text: 'Plano grátis',
            second_button_text: 'Eu quero',
            ul: [
                'Todas as funcionalidades liberadas.',
                'Tenha seus links e cupons reunidos no mesmo lugar.',
                'Acompanhe seus resultados e resgate a qualquer momento.',
                'Acesso aos nossos cursos e materiais.'
            ]
        }
    ]
</script>
<style scoped>
    .plans {
        margin-top: 0px;
        background-color: var(--flip-color-primary);
        position: relative;
    }

    .plans-content {
        padding: 38px 20px;
        position: relative;
        margin: 0 auto;
    }

    .plans-inner {
        margin: 0 auto;
    }

    .plans-inner h6 {
        font-size: max(31px, min(5vw, 43px));
        margin-bottom: 30px;
        line-height: 1.4;
        text-align: left;
        font-weight: 700;
    }

    .plans-inner > p {
        font-size: max(15px, min(5vw, 24px));
        font-weight: 300;
    }

    .plans-inner h6, .plans-inner > p {
        text-align: center;
        color: #fff;
    }

    .plans-inner a {
        height: 60px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        padding-left: 0px;
        max-width: 391px;
        margin: 30px auto 0px auto;
        font-size: max(12px, min(5vw, 17px));
        text-transform: uppercase;
        color: #fff;
        background-color: var(--flip-color-primary);
    }

    .plans-cards {
        margin: 25px auto 0px auto;
        position: relative;
        max-width: 339px;
        border-radius: 16px;
    }

    .plan-card {
        background-color: #F9F9F9;
        border-radius: 20px;
        padding: 1.5rem 1.5rem 2.2rem 1.5rem;
    }

    .plan-card > p {
        font-size: max(56px, min(5vw, 47px));
        font-weight: 800;
        color: var(--flip-color-primary);
        text-align: center;
    }

    .plan-card ul {
        display: grid;
        align-items: center;
        margin: 40px 0px 30px 0px;
        gap: 20px;
    }

    .plan-card ul li {
        display: flex;
        align-items: center;
    }

    .plan-card ul li p {
        padding-left: 15px;
        font-weight: 600;
        color: #000;
        font-size: 12px;
    }

    .plan-card a {
        background-color: #1E1735;
        color: #fff;
        font-weight: 600;
        display: flex;
        justify-content: center;
        padding-left: 0px;
        height: 44px;
        border-radius: 8px;
        text-transform: uppercase;
        margin: 16px 10px 40px 10px;
    }

    .plan-card a:last-child {
        background-color: var(--flip-color-primary);
        font-weight: 700;
        border-radius: 40px;
        height: 46px;
        padding: 0px;
        margin: 0px;
    }

    @media (min-width: 599.99px) {
      .plans-content {
        display: flex;
        gap: 20px;
      }
     
      .plans-inner h6, .plans-inner > p {
        text-align: left;
      }

      .plans-cards {
        margin-top: 0px;
      }
    }

    @media (min-width: 768px) {
        .plans-inner {
            max-width: 400px
        }
    }

    /* Estilos para o modo landscape */
    @media (max-width: 767px) and (orientation: landscape) {
        .plans-cards {
            margin-top: 0px;
        }

        .plans-content > div {
            width: calc(100% / 2);
        }
    }

    @media ( min-width: 1080px ) {
        .plans {
            margin-top: 140px;
        }

        .plans-inner {
            margin-left: 0px !important;
            max-width: 503px;
        }

        .plans-inner h6 {
            margin-top: 0px;
        }

        .plans-inner a {
            width: 202px;
            border-radius: 20px;
            float: left;
            height: 40px;
        }

        .plans-content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        .plans-cards {
            position: absolute;
            top: 47%;
            right: 12vw;
            border: 1px solid #667082;
            transform: translate(0, -52%);
            box-shadow: 0 5px 10px rgba(220, 220, 220, 0.5);
        }

        .plan-card {
            padding: 1.5rem 2rem;
        }
    }
</style>