<template>
  <section class="missions" v-if="missions">
    <h4>Nossas missões</h4>
    <p>Participe de missões, crie conteúdos e receba bonificações especiais!</p>
    <div class="swiper swiper-missions">
      <div class="swiper-wrapper">
        <div v-for="( mission, index ) in missions" :key="index" class="swiper-slide">
          <div class="swiper-slide-media">
            <img :src="mission.asset.url" />
          </div>
          <div class="swiper-slide-content">
            <h3 v-text="mission.title" />

            <p v-html="mission.short_description" />

            <p class="credits" v-html="getLabel(mission.rewards.length, mission.credits_if_complete)" />

            <q-img :src="mission.partner.logo_foto_link" loading="lazy" spinner-color="white"
              style="max-width: 70px; position: absolute; bottom: 0.2rem; right: 1.5rem" />
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>

      <div class="swiper-button-prev desktop-only"></div>
      <div class="swiper-button-next desktop-only"></div>
    </div>
  </section>
</template>
<script setup>
import { landingPageGetMissions } from '../services/index.js';
import { ref, defineProps, watch } from 'vue';
import { getLabel } from '../../modules/main/pages/missions/helpers/script_helpers.js';

const props = defineProps({
  swiperLoaded: Boolean
})

const missions = ref(null)

// Quando a lib Swiper é 100% carregado 'swiperLoaded' vem true... executando a criação dos slides
watch(() => props.swiperLoaded, (newValue) => {
  if ( newValue ) {
    getMissions()
  }
}, { immediate: true })



// METHODS ================================================================================================

// Cria o carousel de cursos
const initializeSwiper = () => {
  setTimeout(() => {
    new window.Swiper('.swiper-missions', {
      speed: 900,
      grabCursor: true,
      breakpoints: {
        50: {
          slidesPerView: 1.1,
        },
        810: {
          slidesPerView: 1.4,
        },
        920: {
          slidesPerView: 1.6
        },
        1090: {
          slidesPerView: 2.1
        }
      },
      spaceBetween: 20,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      on: {
        click: () => {
          window.parent.postMessage('missionsInfo', 'https://flip.net.br');
        }
      },
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  })
}

// Busca as Missões no serviço
const getMissions = () => {
  landingPageGetMissions()
  .then(output => {
      missions.value = { ...output.data }

      initializeSwiper()
  })
  .catch(error => {
      console.log('Houve um erro ao pegar missões', error)
  })
}
</script>
<style scoped>
.missions {
  margin: 60px 0px;
}

.missions h4 {
  font-weight: 700;
  color: #5E5E5E;
  max-width: 90%;
  line-height: 1.5;
  font-size: max(22px, min(5vw, 32px));
  font-weight: 600;
  margin-bottom: 0px;
}

.missions > p {
  font-size: max(15px, min(3.5vw, 18px));
}

.swiper {
  padding: 30px 0px;
}

.swiper-slide {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 0.5rem;
  height: 380px;
  overflow: hidden;
}

.swiper-slide .swiper-slide-content {
  padding: 1.25rem;
}

.swiper-slide .credits {
  line-height: 1.2 !important;
  font-size: max(10px, min(3.5vw, 11px));
  font-weight: 500;
  margin-top: 12px
}

.swiper-slide .swiper-slide-media>img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.swiper-slide h3 {
  font-size: max(14px, min(2.5vw, 16px));
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.swiper-slide p {
  font-size: max(11px, min(2.5vw, 12px));
  display: -webkit-box;
  line-height: 1.15;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  height: auto;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 599.99px) {
  .missions {
    margin: 90px 0px;
  }

  .swiper-slide {
    display: flex;
    height: 200px
  }

  .swiper-slide .swiper-slide-media>img {
    width: 300px;
  }

  .swiper-slide .credits {
    margin-top: 10px
  }

  .swiper-slide .swiper-slide-media>img {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0rem;
  }
}
</style>