<template>
    <section class="brands">
        <h4 class="text-center uppercase">Algumas marcas que confiam em nós</h4>
        <div class="brands-logos gap-10 grid sm:flex sm:justify-center">
            <div v-for="( logo, index ) in partners" :key="index" class="brand-logo">
                <img :src="logo" />
            </div>
        </div>
    </section>
</template>
<script setup>
   const partners = [
    'https://flip.net.br/wp-content/uploads/2024/06/partner-1.png',
    'https://flip.net.br/wp-content/uploads/2024/10/partner-2.png',
    'https://flip.net.br/wp-content/uploads/2024/06/partner-3.png',
    'https://flip.net.br/wp-content/uploads/2024/10/partner-4-e1728317262972.png',
    'https://flip.net.br/wp-content/uploads/2024/06/partner-5.png',
    'https://flip.net.br/wp-content/uploads/2024/10/partner-6-e1728316868475.png'
   ]
</script>
<style scoped>
.brands {
    margin-top: 64px;
}

.brands h4 {
    margin: 0px auto 0px auto;
    max-width: 80%;
    letter-spacing: 2.52px;
    font-size: max(14px, min(3.5vw, 14px));
    text-transform: uppercase;
    line-height: 1.2;
}

.brands-logos {
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    justify-content: center; 
    gap: 40px;
    margin-top: 45px;
}

.brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    max-height: 20px;
}

ul {
    display: grid;
    gap: 16px;
}

@media ( min-width: 920px ) {
    .brands-logos {
        justify-content: space-between;
    }
}
</style>