import {
    baseUrlMissions, baseUrlLearning
} from "@/shared/http/api";

const fetchData = async (endpoint) => {
    try {
        const response = await fetch(endpoint, { method: 'GET' });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

export const landingPageGetMissions = () => {
    const ENDPOINT = `${baseUrlMissions}/api/v1/missions/public?&vigente=true`;

    return fetchData(ENDPOINT);
};

export const landingPageGetCourses = () => {
    const ENDPOINT = `${baseUrlLearning}/api/v1/public/courses/enroll`;
    
    return fetchData(ENDPOINT);
};