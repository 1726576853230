import script from "./faq.vue?vue&type=script&setup=true&lang=js"
export * from "./faq.vue?vue&type=script&setup=true&lang=js"

import "./faq.vue?vue&type=style&index=0&id=3f18ba27&lang=css"

const __exports__ = script;

export default __exports__
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QExpansionItem,QCard,QCardSection,QItem,QIcon});
