<template>
    <section class="talk-about-us">
        <h4 class="">O que falam sobre nós</h4>
        <div class="comments">
            <div class="comment" v-for="( comment, index ) in comments" :key="index">
                <div>
                    <p>{{ comment.text }}</p>
                    <div class="comment-content">
                        <div class="comment-thumb">
                            <img :src="comment.author.thumb" :alt="comment.author.name" />
                        </div>
                        <div class="comment-inner">
                            <b>{{ comment.author.name }}</b>
                            <p>{{ comment.author.office }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
const comments = [
    { 
        text: '“Eu já tenho alguns meses trabalhando com a Flip, e a plataforma é incrível, o manuseio é muito intuitivo, as empresas inseridas são muito boas, as missões ajudam muito na melhora de nosso desempenho nas vendas, o suporte sempre me atendeu e me ajudou de forma rápida.”',
        author: {
            name: 'Eduardo Rosa',
            office: 'Empresário',
            thumb: 'https://flip.net.br/wp-content/uploads/2024/06/comment-1.png'
        }
    },
    { 
        text: `“Eu sempre elogio e indico o Flip para minhas amigas que criam conteúdo. 
É uma plataforma que não se importa sobre os números de nós criadores, e sim, pelo nosso conteúdo, o que dá mais oportunidade para quem está iniciando.
As empresas parceiras de vocês também são excelentes, sou fã da plataforma e grata pela oportunidade. Só gratidão e contem comigo 🫶.”`,
        author: {
            name: 'Amanda Lima',
            office: 'Criadora de conteúdo',
            thumb: 'https://flip.net.br/wp-content/uploads/2024/06/comment-2.png'
        }
    },
    { 
        text: '“Bom eu iniciei mesmo na criação de conteúdo através de vocês, então vocês foram simplesmente o início de tudo. Tive a oportunidade de trabalhar com marcas incríveis e que sempre gostei, por exemplo: Simple Orgânic, Amend, Sou básico e tantas outras. [...] Gostaria até de agradecer por terem me apresentado esse mundo, onde hoje, consigo ter meu salário. 💗”',
        author: {
            name: 'Duda Giachetto',
            office: 'Influencer',
            thumb: 'https://flip.net.br/wp-content/uploads/2024/06/comment-3_.png'
        }
    }
]
</script>
<style scoped>
.talk-about-us {
    margin-top: 96px;
}

.talk-about-us h4 {
    font-size: max(20px, min(5vw, 32px));
    text-align: center;
}

.comments {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-left: 1px solid #e6e6eb;
}

.comments .comment {
    padding: 1.5rem 2rem;
    width: calc(100% / 3);
}

.comments .comment > div > p {
    text-align: justify;
    font-size: 16px;
    min-height: 240px;
    color: #6B7280;
}

.comment-content {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comment-inner {
    margin-left: 16px;
}

.comment-inner b {
    font-size: 18px;
    color: #111928;
    font-weight: 600;
    margin-bottom: 4px;
}

.comment-inner p {
    color: #6B7280;
    font-size: 14px;
    font-weight: 500;
}

@media (min-width: 1180px) {
    .comments .comment > div {
        width: 322px;
    }
}
</style>