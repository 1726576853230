<template>
    <section class="solution">
        <div class="solution-content">
            <div class="solution-inner">
                <h6><span>Tudo</span> que você precisa em <span>uma solução</span>.</h6>
                <div>
                    <p>O <span>Flip.net</span> é uma plataforma inovadora que conecta parceiros a grandes marcas do mercado. Aqui, você pode criar sua própria loja online e ganhar renda extra vendendo produtos de marcas renomadas.</p>
                    <p>Vamos simplificar o que oferecemos:</p>
                </div>
            </div>
            <div class="solution-img">
                <img src="https://flip.net.br/wp-content/uploads/2024/06/solution.png" 
                alt="O FlipNet é uma plataforma inovadora que conecta parceiros a grandes marcas do mercado. Aqui, você pode criar sua própria loja online e ganhar renda extra vendendo produtos de marcas renomadas." loading="lazy"/>
            </div>
        </div>
    </section>
</template>
<style scoped>
.solution {
    margin-top: 80px;
}

.solution-img {
    max-width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

.solution-inner {
    max-width: 721px;
    padding: 0 20px;
}

.solution-inner h6 {
    font-size: max(32px, min(5vw, 34px));
    line-height: 1.3;
    font-weight: 600;
}

.solution-inner h6 span:first-child {
    font-weight: 700;
}

.solution-inner h6 span:last-child {
    color: var(--flip-color-primary);
    font-weight: 700;
}

.solution-inner > div {
    margin-top: 40px;
}

.solution-inner p {
    font-weight: 500;
    color: #000;
    font-size: max(16.69px, min(2vw, 18px));
}

.solution-inner p:last-child {
    margin-top: 1.4rem;
}

.solution-inner p span {
    color: var(--flip-color-primary);
    font-weight: 700;
}

.solution-inner h6, .solution-inner p {
    text-align: left;
}

@media ( max-width: 450px ) {
    .solution-img img {
        width: 100%;
        max-width: 524px;
    }
}

@media ( min-width: 599.99px ) {
    .solution-inner {
        margin: 0 auto;
    }
}

@media ( min-width: 1180px ) {
    .solution {
        margin-top: 116px;
    }

    .solution-content {
        display: flex;
        align-items: center;
    }
    
    .solution-img img {
        max-width: none;
        margin-left: 107px;
    }
}

</style>