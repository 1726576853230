<template>
  <section class="faq">
    <h4>Dúvidas frequentes</h4>
    <p>Conheça mais sobre o Flip.net</p>
    <q-list
      dark
      padding
      bordered
      class="rounded-borders"
      style="max-width: 328px"
    >
      <q-expansion-item
        v-for="(item, index) in faq"
        group="somegroup"
        :label="item.question"
        :key="index"
      >
        <q-card>
          <q-card-section>
            <div v-html="item.answer" />
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
  </section>
</template>

<script setup>
const faq = [
  {
    question: "O que é o Flip.net?",
    answer: ` O Flip.net é uma plataforma de venda e divulgação por parceiros. Trabalhamos com marcas de médio e grande porte. Normalmente a marca provê um link e cupom únicos para o parceiro, a cada venda gerada, o parceiro ganha uma bonificação.`,
  },
  {
    question: "O que é um parceiro?",
    answer: `Um parceiro é um usuário da plataforma que pode ser criador de conteúdo, afiliado, influenciador, empreendedor, revendedor e até mesmo estudante/profissional de marketing. 
            O parceiro se cadastra na plataforma e, quando aprovado, ganha um link e/ou cupom de desconto para divulgar e gerar vendas.
            `,
  },
  {
    question: `O que é um programa?`,
    answer: `Um programa é o nome dado à parceria que é estabelecida entre marca e parceiro. Por exemplo, todos os parceiros do Flipnet (pessoas que estão cadastradas na nossa plataforma) fazem parte do Flip Squad (programa).`,
  },
  {
    question: "Como funciona?",
    answer: `Você indica produtos por meio de seu link ou cupom das marcas na plataforma. À medida que as vendas ocorrem você acompanha tudo no seu painel. Ao final de um período pré-estabelecido, seu valor financeiro é apurado e você recebe um PIX, crédito em conta ou cupom de premiação.`,
  },
  {
    question: `Preciso ser um influenciador?`,
    answer: `Na verdade não, na plataforma são bem vindos criadores de conteúdo, afiliados, influenciadores, empreendedores, revendedores, estudantes e profissionais de marketing. Quem se cadastrada é chamado de parceiro. O parceiro se cadastra na plataforma e, quando aprovado, está apto a iniciar a divulgação.`,
  },
  {
    question: "Eu preciso pagar alguma coisa para usar a plataforma?",
    answer: `Não! O Flip.net é uma plataforma gratuita, não sendo necessário nenhum tipo de investimento financeiro para usá-la.`,
  },
  {
    question: "O que são missões?",
    answer: `Missões são campanhas criadas pelas marcas com a finalidade de incentivar a criação de conteúdo, venda e indicação de novos parceiros.`,
  },
  {
    question: "Não fui aprovado na missão, e agora?",
    answer:
      "É importante dizer que você não precisa ser aprovado em uma missão específica para divulgar o seu link ou cupom da marca. Muito pelo contrário, as marcas tendem à aprovar nas missões os parceiros que já possuem algum nível de divulgação e engajamento prévio.",
  },
  {
    question: "Todos os pedidos geram bonificação na plataforma Flip.net?",
    answer:
      "Somente os pedidos pagos, faturados, não cancelados e não devolvidos (ainda que parcialmente) geram bonificação. As marcas costumam esperar o prazo de arrependimento do e-commerce (7 dias), antes de creditar a bonificação ao parceiro.",
  },
  {
    question: "Qual é a data de recebimento da bonificação?",
    answer: `A data de recebimento da bonificação pode variar de marca para marca. Consulte o menu “meus resgates” no dashboard de cada marca para saber a data do recebimento.`,
  },
  {
    question: "Como eu solicito um resgate?",
    answer: `Você precisa acessar o dashboard da marca onde tem créditos para resgatar e ir no menu lateral esquerdo “resgatar créditos”.
            <p>
                 Aqui é importante ressaltar que se você se cadastrou como Pessoa Física, na hora de solicitar seu resgate, serão recolhidos impostos para evitar que você tenha problemas com a receita federal. 
            </p>
            <p>
                Agora, se você se cadastrou como Pessoa Jurídica, é necessário emitir uma nota e nos enviar para que possamos fazer o seu pagamento.
            </p>
            `,
  },
  {
    question: "Posso me cadastrar como pessoa física ou jurídica?",
    answer: `Sim, você pode se cadastrar como pessoa física ou jurídica. Para pessoas físicas a carga de imposto é bem maior, de forma que encorajamos bastante você a se cadastrar como pessoa jurídica. Como pessoa jurídica, os CNAES aceitos para emissão de nota fiscal usualmente são os seguintes: 
            <p>
                <ul>
                    <li>
                    7319-0/02 - Promoção de vendas
                    </li>
                    <li>
                    7319-0/99 - Outras atividades de publicidade não especificadas anteriormente
                    </li>
                    <li>
                    7420-0/021 - Atividades de produção de fotografia exceto aérea e submarina
                    Válidos para parceiro ME
                    </li>
                     <li>
                    7311-4/00 - Agências de publicidade (não incluso no MEI)
                    </li>
                    <li>
                    7319-0/03 - Marketing direto (não incluso no MEI)
                    </li>
                    <li>
                     7319-0/04 - Consultoria em publicidade (não incluso no MEI)
                    </li>
                </ul>
            </p>       
            `,
  },
  // {
  //     question: 'O que é o Dashboard centralizado Flip.net?',
  //     answer: `O Dashboard centralizado é um pedido de alguns parceiros que usam a plataforma do Flip.net há algum tempo. O objetivo é reunir as informações, cupons e links das marcas em um único lugar  para que você parceiro possa ter acesso à essas informações de uma maneira simplificada. No entanto, somente os programas abertos são listados no dashboard centralizado.`
  // },
  // {
  //     question: 'Todas as marcas do Flip.net estão disponíveis no dashboard centralizado?',
  //     answer: `Não, nesta fase beta estão disponíveis apenas 5 marcas e posteriormente somente as marcas que têm programa abertos (algumas marcas possuem programas fechados, específicos para um público determinado).`
  // },
  {
    question: `Onde eu tenho os links dos programas?`,
    answer: `Você se cadastrando em nossa plataforma agora, terá acesso a um painel com os programas das marcas que estão disponiveis para cadastro. 
Além disso, ao ser aprovado nos programas escolhidos, os seus cupons e links serão centralizados em único lugar para que você possa ter acesso à essas informações de maneira simplificada.`,
  },
  {
    question: "Me cadastrei e preciso de ajuda, o que eu faço?",
    answer: `Teremos prazer em te ajudar! Entre em contato pelo email <a href="mailto:suporte@flip.net.br" class="anchor-link-external text-dark"><strong>suporte@flip.net.br</strong></a> ou no WhatsApp do suporte <strong>(11) 93466-2703</strong>. Informe sempre seu 
            <strong>ID de parceiro</strong>, para agilizar seu atendimento`,
    info: {
      text: `Seu ID pode ser localizado no topo superior direito, clicando em seu nome.`,
      img: `https://firebasestorage.googleapis.com/v0/b/andre-projetos.appspot.com/o/copy-my-id.png?alt=media&token=797d6b6a-5721-429d-9e10-f55cb9d3e529`,
    },
  },
];
</script>

<style>
.faq {
  margin-top: 35px;
}

.faq h4 {
  font-size: max(28px, min(5vw, 32px));
  font-weight: 600;
}

.faq p {
  font-size: 15px;
  color: #000;
  margin: 15px 0px 10px 0px;
}

.q-expansion-item__container {
  border: 1px solid #cbcbcb;
  border-radius: 8px;
  padding: 5px 0px;
  margin-bottom: 20px;
}

.q-item__label {
  font-weight: 600;
  color: #000;
}

.q-item__section--side > .q-icon {
  color: var(--flip-color-primary);
}

.faq .q-card {
    background-color: transparent;
}

.q-card__section--vert {
    color: #000;
    padding-top: 8px;
}

.q-list {
  max-width: 100% !important;
}

.faq button {
  border: none;
  background-color: transparent;
  outline: none;
  text-decoration: underline;
  padding: 0px;
  font-weight: bold;
  cursor: pointer;
}

@media (min-width: 599.99px) {
  .faq {
    padding: 0px 10px;
  }

  .q-expansion-item__container {
    padding: 5px 15px !important;
  }
}

@media (min-width: 1080px) {
  .faq {
    margin-top: 100px;
  }
}
</style>
