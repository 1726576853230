<template>
  <section class="info">
    <div class="columns">
      <div class="column">
        <div v-for="(item, index) in firstColumn" :key="index">
          <div>
            <img :src="item.icon" alt="" />
            <h3 v-text="item.title"/>
            <div>
              <p v-text="item.text" />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div v-for="(item, index) in secondColumn" :key="index">
          <div>
            <img :src="item.icon" alt="" />
            <h3 v-text="item.title" />
            <div>
              <p v-text="item.text" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a @click="goToForm" class="button-go-to-form button-scale-animation">
      <span>Comece agora</span>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
          ></path>
        </svg>
      </span>
    </a>
  </section>
</template>
<script setup>
import { defineProps } from 'vue';

defineProps({
    goToForm: {
        type: Function,
        required: true
    }
})

const firstColumn = [
  {
    title: `Escolha de marcas:`,
    text: `Você é o dono da sua loja online! Selecione quais marcas ou segmentos de negócio deseja trabalhar.`,
    button_text: `Conheça o painel`,
    icon: `https://flipnet-assets.s3.sa-east-1.amazonaws.com/blog-lp/icon-escolha-de-marcas.png`,
  },
  {
    title: `Treinamento em vendas:`,
    text: `Tenha acesso a diversos treinamentos que te ajudam a obter resultados ainda melhores com nossa plataforma.`,
    button_text: `Acompanhar`,
    icon: `https://flipnet-assets.s3.sa-east-1.amazonaws.com/blog-lp/icon-treinamento-em-vendas.png`,
  },
];

const secondColumn = [
  {
    title: `Controle total:`,
    text: `Acompanhe suas vendas, comissões e datas de recebimento através de um painel completo.`,
    button_text: `Configurar loja`,
    icon: `https://flipnet-assets.s3.sa-east-1.amazonaws.com/blog-lp/icon-controle-total.png`,
  },
  {
    title: `Personalização:`,
    text: `Você consegue destacar as melhores ofertas das marcas que escolheu vender pela tela inicial da plataforma.`,
    button_text: `Escolher marcas`,
    icon: `https://flipnet-assets.s3.sa-east-1.amazonaws.com/blog-lp/icon-escolha-de-marcas.png`,
  },
];
</script>
<style scoped>
.info {
  margin-top: 100px;
}

.columns {
  padding: 0 20px;
}

.column > div {
  margin-bottom: 46px;
  width: 100%;
}

.column img {
  width: 42px;
  height: 42px;
  object-fit: cover;
  object-fit: top;
  margin-bottom: 15px;
}

.column h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 19px;
}

.column p {
  font-size: max(16, min(5vw, 18));
  font-weight: 400;
  color: #000;
  max-width: 350px;
}

a {
  color: #fff !important;
  background-color: var(--flip-color-primary) !important;
  font-weight: 600 !important;
  margin-top: 0px;
  margin-bottom: 50px;
  text-align: left;
  padding: 12px 35px !important;
  position: relative;
  height: fit-content !important;
  align-items: center !important;
  border: 1px solid transparent;
  width: fit-content;
  margin-left: 18px;
}

a span:last-child {
  padding-left: 46px;
}

a svg {
  position: relative;
  top: 0.2rem;
  fill: #fff;
}

@media (min-width: 599.99px) {
  .columns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  a {
    margin-top: 20px;
    margin-left: auto;
  }
}

@media (min-width: 1180px) {
  .columns {
    justify-content: start;
    gap: 0;
  }

  .column a {
    margin-top: 40px;
  }

  .column p {
    max-height: 72px;
  }

  .column:first-child {
    border-right: 1px solid var(--flip-color-primary);
  }

  .column > div:first-child {
    border-bottom: 1px solid var(--flip-color-primary);
    padding-bottom: 55px;
  }

  .column:last-child {
    flex-grow: 1;
  }

  .column > div {
    padding: 0px 50px;
  }

  .column:first-child > div {
    padding-left: 0px;
    padding-right: 200px;
  }
}
</style>
